import React from "react";
import ReactDOM from "react-dom";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Preloader from "./components/Preloader/Preloader";
import Timer from "./components/Countdown/Timer";
import Optin from "./components/Optin/Optin";

import "./styles.css";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

function App() {
  // Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD7FTJ-SzkCyDV6VylatHXS8isMUqAulkw",
  authDomain: "avanteq-cba94.firebaseapp.com",
  projectId: "avanteq-cba94",
  storageBucket: "avanteq-cba94.appspot.com",
  messagingSenderId: "894432392894",
  appId: "1:894432392894:web:dab677dcee53abfd6810ef",
  measurementId: "G-LGHEEHF47J"
};

React.useEffect(()=>{
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  //const analytics = getAnalytics(app);

},[])


  return (
    <div className="App">
      <div className="container">
        <h1>
          Website
          <br />
          Coming Soon
        </h1>
        <Timer />
        <Optin />
        <Preloader />
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
